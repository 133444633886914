import trackEvent from '../../helpers/gtm/eventPush';

const getVerified = document.querySelector('.button--verified');

const getVerifiedContinue = document.querySelector(
  '.button--verified-continue',
);

if (getVerified) {
  getVerified.addEventListener('click', () => {
    trackEvent({
      category: 'call to action',
      action: 'get_verified',
      label: window.location.href,
    });
  });
}
if (getVerifiedContinue) {
  getVerifiedContinue.addEventListener('click', () => {
    trackEvent({
      category: 'call to action',
      action: 'get_verified_continue',
      label: window.location.href,
    });
  });
}
