import '../../../sass/apps/reg_prompt/reg_prompt.scss';
import addParametersToURLS from '../../helpers/addParametersToURLs';
import { fetchData } from '../../helpers/base_data_fetcher';

const addTrackingParameterToRegLink = (logRetPath) => {
  const addParametersToLoginReturnPath = addParametersToURLS(logRetPath);
  const loginReturnPath = addParametersToLoginReturnPath({ reg_popup: 1 });
  const regLink = document.getElementById('reg-prompt-register');
  const path = regLink.pathname;
  return (regLink.href = `${path}?loginfrom_url=${encodeURIComponent(
    loginReturnPath,
  )}`);
};

const addGAEvents = () => {
  const eventObject = {
    event: 'ga.event',
    ga_event_category: 'registration',
  };
  document.querySelectorAll('.button--facebook').forEach((elem) => {
    elem.onclick = () => {
      if (elem.id == 'fb_login-reg-promp') {
        eventObject.ga_event_action = 'reg_popup_facebook';
        dataLayer.push(eventObject);
      } else {
        dataLayer.push(eventObject);
      }
    };
  });

  document.querySelectorAll('#reg-prompt-register').forEach((elem) => {
    elem.onclick = () => {
      eventObject.ga_event_action = 'reg_popup_email';
      dataLayer.push(eventObject);
    };
  });

  document.querySelectorAll('#reg_remind_me_later').forEach((elem) => {
    elem.onclick = () => {
      eventObject.ga_event_action = 'reg_popup_remind_later';
      dataLayer.push(eventObject);
    };
  });

  document.querySelectorAll('#reg_login').forEach((elem) => {
    elem.onclick = () => {
      eventObject.ga_event_action = 'reg_popup_login';
      dataLayer.push(eventObject);
    };
  });

  document.querySelectorAll('#reg_close').forEach((elem) => {
    elem.onclick = () => {
      eventObject.ga_event_action = 'reg_popup_close';
      dataLayer.push(eventObject);
    };
  });

  document.querySelectorAll('#overlay-mask').forEach((elem) => {
    elem.onclick = () => {
      eventObject.ga_event_action = 'reg_popup_close';
      dataLayer.push(eventObject);
    };
  });

  document.addEventListener('keydown', (event) => {
    if (event.keyCode === 27) {
      eventObject.ga_event_action = 'reg_popup_close';
      dataLayer.push(eventObject);
    }
  });
};

const createPopUp = (headingText, trackingLabelPartOne) => {
  // replace with new facebook_connect in next iteration
  if (window.SR && !SR.facebookConnect.loaded()) {
    SR.facebookConnect.init();
  }
  if (!trackingLabelPartOne) {
    trackingLabelPartOne = 'on_click';
  }
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'ga.event',
      ga_event_action: 'reg_popup_show',
      ga_event_category: 'registration',
      ga_event_label: `${trackingLabelPartOne}`,
    });
  }

  setTimeout(() => {
    const popUpTrigger = document.getElementById('show-reg-reminder-popup');
    const popUpTarget = document.getElementById('reg_popup');
    const popUpHeading = document.querySelector('#reg_popup .heading');
    if (popUpTrigger && popUpTarget) {
      popUpTrigger.click({
        trigger: popUpTrigger,
        target: popUpTarget,
      });
      popUpTarget.removeAttribute('style');
    }
    if (headingText) {
      popUpHeading.innerText = `Thanks for using ${headingText}, now register for FREE`;
    }
    if (window.dataLayer) {
      addGAEvents();
    }
  }, 50);
};

const init = (trackingLabelPartOne, headingText) => {
  fetchData('page.loginReturnPath')
    .then((response) => addTrackingParameterToRegLink(response))
    .catch((err) => console.info(err));
  return createPopUp(headingText, trackingLabelPartOne);
};

export default init;
