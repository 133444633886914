import { fetchData } from '../../helpers/base_data_fetcher';
import getActiveExperimentVariant from '../../experiments/helpers/optimize.js';
import showRegPromptAfterOneMinute from '../../apps/reg_prompt/afterOneMinute';

async function triggerExperiment(experiment_id) {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: `optimize.activate.removal_of_reg_popup_for_paid_users_mob`,
    });
    try {
      const variant = await getActiveExperimentVariant(experiment_id);
      if (variant != 0) {
        // console.log('showRegPromptAfterOneMinute');
        showRegPromptAfterOneMinute();
      }
    } catch (error) {
      console.log(error.message);
    }
  }
}

const triggerRegistrationPromptMobile = async () => {
  try {
    const [isLoggedIn, affiliate, features, experiments] = await Promise.all([
      fetchData('session.loggedin'),
      fetchData('affiliate'),
      fetchData('config.features'),
      fetchData('experiments'),
    ]);
    if (isLoggedIn) {
      return false;
    }
    const { blocking_reg_prompt, one_minute_reg_prompt } = features;
    const { removal_of_reg_popup_for_paid_users_mob } = experiments;

    if (affiliate.category !== 'adwords' && one_minute_reg_prompt) {
      return showRegPromptAfterOneMinute();
    }
    if (affiliate.category === 'adwords' && blocking_reg_prompt) {
      if (removal_of_reg_popup_for_paid_users_mob) {
        triggerExperiment(removal_of_reg_popup_for_paid_users_mob);
      }
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default triggerRegistrationPromptMobile;
