import trackEvent from '../helpers/gtm/eventPush';
import '../../sass/components/home/lrf_banner.scss';

const results = document.querySelector('.listing-result');
const homePage = document.querySelector('.home-sections');
const lrfPromptMountElement = document.querySelector('#liverentfree');
const lrfBanner = document.querySelector('.lrf-banner__link');

function trackBanner() {
  const lrfLink = lrfBanner.getAttribute('href');
  const lrfVersion = lrfBanner.getAttribute('data-version');
  lrfBanner.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'srp_promo_panel_click',
      label: `${lrfVersion}|${window._sr.page.name}|${lrfLink}`,
    });
  });
}

function trackBannerView() {
  const lrfVersion = lrfBanner.getAttribute('data-version');
  trackEvent({
    category: 'banners',
    action: 'srp_promo_panel_view',
    label: `${lrfVersion}|${window._sr.page.name}`,
    non_interaction: true,
  });
}

if (results && lrfPromptMountElement) {
  lrfPromptMountElement.classList.add('liverentfree--loaded');
  trackBanner();
  trackBannerView();
}

function trackBannerHome() {
  const lrfLink = lrfBanner.getAttribute('href');
  const lrfVersion = lrfBanner.getAttribute('data-version');
  lrfBanner.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'hp_banner_click',
      label: `${lrfVersion}|${lrfLink}`,
    });
  });
}

function trackBannerHomeView() {
  const lrfVersion = lrfBanner.getAttribute('data-version');
  trackEvent({
    category: 'banners',
    action: 'hp_banner_view',
    label: `${lrfVersion}`,
    non_interaction: true,
  });
}
if (homePage && lrfPromptMountElement) {
  trackBannerHome();
  trackBannerHomeView();
}
