import trackEvent from '../helpers/gtm/eventPush';
import '../../sass/components/home/tube_ad_banner.scss';

const tubeAdBanner = document.querySelector('.tube-ad__link');

function trackBannerTube() {
  const tubeAdLink = tubeAdBanner.getAttribute('href');
  tubeAdBanner.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'offerer_tube_cross_promo_panel_click',

      label: `1f|${tubeAdLink}`,
    });
  });
}

function trackBannerTubeView() {
  trackEvent({
    category: 'banners',
    action: 'offerer_tube_cross_promo_panel_view',
    label: '1f',
    non_interaction: true,
  });
}

if (tubeAdBanner) {
  trackBannerTube();
  trackBannerTubeView();
}
