import Cookies from 'js-cookie';
import get from '../../helpers/data_layer/get';
import regPrompt from '.';

const oneMinute = 1 * 60 * 1000;

function isMoreThanMinute(timeStamp) {
  const now = new Date().getTime();
  if (now - parseInt(timeStamp, 10) > oneMinute) {
    return false;
  }
  return true;
}

export default function() {
  const userId = Cookies.get('user_id');
  const alreadyShown = Cookies.get('reg_prompt_shown');
  const timeOnSiteCookie = Cookies.get('time_arrived_stamp');
  const tooSoon = timeOnSiteCookie ? isMoreThanMinute(timeOnSiteCookie) : true;
  if (userId || alreadyShown || tooSoon) {
    return false;
  }
  const domain = get('site.domain');
  const options = { expires: 2 };
  if (domain) {
    options.domain = domain;
  }
  Cookies.set('reg_prompt_shown', 1, options);
  const trackingLabelPartOne = '60s';
  return regPrompt(trackingLabelPartOne);
}
