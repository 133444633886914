function getActiveExperimentVariant(experimentId, { timeout = 4000 } = {}) {
  return new Promise((resolve, reject) => {
    // Resolve the promise because google_optimize is discontinued
    return resolve(false);
    /*
    const startTime = new Date().getTime();
    const intervalId = setInterval(() => {
      if (new Date().getTime() - startTime > timeout) {
        // google_optimize has failed to load on the window, so reject
        clearInterval(intervalId);
        return reject(new Error('google_optimize was not found on the window'));
      }
      try {
        if (window.google_optimize !== undefined) {
          const variant = window.google_optimize.get(experimentId);
          // We have the info we need let's return the variant id
          // (this will be undefined if the experiment is not currently active)
          clearInterval(intervalId);
          if (variant === undefined) {
            return reject(
              new Error(`The experiment with id ${experimentId} is not active`),
            );
          }
          return resolve(variant);
        }
        return false;
      } catch (error) {
        // oh dear, something has gone wrong REJECT!
        clearInterval(intervalId);
        return reject(error);
      }
    }, 200);
    */
  });
}

export default getActiveExperimentVariant;
