import showRegPromptAfterOneMinute from '../../apps/reg_prompt/afterOneMinute';
import { fetchData } from '../../helpers/base_data_fetcher';

const triggerRegistrationPrompt = async () => {
  try {
    const [isLoggedIn, affiliate, features] = await Promise.all([
      fetchData('session.loggedin'),
      fetchData('affiliate'),
      fetchData('config.features'),
    ]);
    if (isLoggedIn) {
      return false;
    }

    const { one_minute_reg_prompt } = features;
    if (affiliate.category !== 'adwords' && one_minute_reg_prompt) {
      return showRegPromptAfterOneMinute();
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export default triggerRegistrationPrompt;
