import trackEvent from '../../helpers/gtm/eventPush';

const wantedButton = document.getElementById('wantedButton');
const theyFindYou = document.getElementById('theyFindYou');
const offeredButton = document.getElementById('offeredButton');

const smallOffered = document.getElementById('smallOffered');
const wantedButtonSeeker = document.getElementById('wantedButtonSeeker');
const smallWanted = document.getElementById('smallWanted');
const offeredButtonOfferer = document.getElementById('offeredButtonOfferer');
const findOutMore = document.getElementById('findOutMore');

if (wantedButton) {
  wantedButton.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'hp_promo_seekers',
      label: 'advertise_for_free',
    });
  });
}

if (offeredButton) {
  offeredButton.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'hp_promo_offerers',
      label: 'post_a_free_ad',
    });
  });
}

if (smallOffered) {
  smallOffered.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'hp_room_offered_promo_link',
      label: 'post_a_free_ad',
    });
  });
}
if (wantedButtonSeeker) {
  wantedButtonSeeker.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'hp_room_wanted_promo_link',
      label: 'post_a_free_ad',
    });
  });
}

if (smallWanted) {
  smallWanted.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'hp_promo_room_wanted',
      label: 'post_a_free_room_wanted_ad',
    });
  });
}

if (offeredButtonOfferer) {
  offeredButtonOfferer.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'hp_room_wanted_promo_link',
      label: 'post_a_free_ad',
    });
  });
}

if (theyFindYou) {
  theyFindYou.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'hp_promo_seekers',
      label: 'make_sure_they_find_you',
    });
  });
}

if (findOutMore) {
  findOutMore.addEventListener('click', () => {
    trackEvent({
      category: 'banners',
      action: 'hp_promo_offerers',
      label: 'find_out_more',
    });
  });
}
