import axios from 'axios';
import storageAvailable from '../../helpers/storage_available';

/**
 * Stores the user's most recent listing to local storage to be used for
 * attributing to upgrades as part of enhanced ecommerce tracking - src/js/apps/commerce/tracking/index.js
 *
 * @export
 * @param {boolean} [forceUpdate=false]
 * @returns
 */
export default function(forceUpdate = false) {
  if (!storageAvailable('localStorage')) {
    return false;
  }
  const now = Date.now();
  const myNewestListing = JSON.parse(
    window.localStorage.getItem('newest-profile'),
  );

  function getMyListings() {
    return axios.get(`/flatshare/mylistings.pl?format=json&max_per_page=1`);
  }

  function saveNewestListing(listing) {
    const newestListing = {
      date_checked: Date.now(),
      profile: listing,
    };
    return window.localStorage.setItem(
      'newest-profile',
      JSON.stringify(newestListing),
    );
  }

  function getNewestListing(listings) {
    return listings.sort(
      (a, b) => Date.parse(b.date_live_iso8601) - Date.parse(a.date_live_iso8601),
    )[0];
  }
  function processMyListings(listings) {
    let allListings;
    if (listings.myads) {
      // should check they are not deactivated first? possibly not…
      if (listings.myads.offered && listings.myads.wanted) {
        allListings = listings.myads.offered.concat(listings.myads.wanted);
      } else {
        allListings = listings.myads.offered
          ? listings.myads.offered
          : listings.myads.wanted;
      }
      const newest = getNewestListing(allListings);
      return saveNewestListing(newest);
    }
    return false;
  }

  async function updateMyNewestListing() {
    try {
      const data = await getMyListings();
      return processMyListings(data.data);
    } catch (error) {
      return console.log(error);
    }
  }

  if (
    !myNewestListing
    || !myNewestListing.date_checked
    || now - myNewestListing.date_checked > 1800000
    || forceUpdate
  ) {
    return updateMyNewestListing();
  }
  return false;
}
